<template>
	<main-table-a>
		<div slot="table" class="df f1 fdc">
			<div class="df fww">
				<div class="df mr20 mb20 br5 oh">
					<div class="p10 miw100 cp" :style="'background-color: ' + (status.checked ? '#b7a06a' : '#e2d6ba')" v-for="(status, s) in status_tabs" :key="s" @click="toggleStatus(s)">
						<div class="fs14 c6">{{status.name}}</div>
						<div class="fs16 fw6 c3">{{counts['status_'+s] || 0}}</div>
					</div>
				</div>
				<div class="df mr20 mb20 br5 oh">
					<div class="p10 miw100 cp" :style="'background-color: ' + (type.checked ? '#b7a06a' : '#e2d6ba')" v-for="(type, t) in type_tabs" :key="t" @click="toggletype(t)">
						<div class="fs14 c6">{{type.name}}</div>
						<div class="fs16 fw6 c3">{{counts['type_'+t] || 0}}</div>
					</div>
				</div>
				<div class="df mr20 mb20 br5 oh">
					<div class="p10 miw100 cp" :style="'background-color: ' + (other.checked ? '#b7a06a' : '#e2d6ba')" v-for="(other, o) in other_tabs" :key="o" @click="getContracts({ ...params, other: other.checked ? '' : o})">
						<div class="fs14 c6">{{other.name}}</div>
						<div class="fs16 fw6 c3">{{counts[o] || 0}}</div>
					</div>
				</div>
				<div class="mb10">
					<el-button type="primary" size="mini" @click="show_fillter = !show_fillter" :plain="!show_fillter">
						{{show_fillter ? '收起' : '筛选'}}
						<el-tooltip>
							<div slot="content" class="lh1_5">
								<p>本列表不支持创建添加，数据来源于人事在员工列表登记办理</p>
							</div>
							<i class="el-icon-question ch"></i>
						</el-tooltip>
					</el-button>
				</div>
			</div>
			<el-form :inline="true" :model="params" :size="theme.size" v-if="show_fillter">
				<el-form-item>
					<el-input v-model="params.keyword" placeholder="编号/工作地点/任职岗位/终止原因" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.employees" placeholder="请选择雇佣人" filterable  multiple collapse-tags>
						<el-option class="df aic jcsb" v-for="employee in employees" :key="employee.id" :label="employee.name" :value="employee.id">
							<span>{{ employee.name }}</span>
							<span class="c9 fs12">{{counts["employee_"+employee.id] || 0}}</span>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select slot="label" v-model="params.date_type" placeholder="请选择时间类型" filterable clearable>
						<el-option label="签订日期" value="sign"></el-option>
						<el-option label="生效日期" value="effective"></el-option>
						<el-option label="试用日期" value="probation"></el-option>
						<el-option label="到期日期" value="expiration"></el-option>
					</el-select>
					<el-date-picker v-model="params.date" :picker-options="$config.DATE_PICKER" type="daterange" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getContracts({ ...params, page: 1})">查询</el-button>
				</el-form-item>
			</el-form>
			<div class="f1 pr">
				<el-table ref="employee_table" class="bill scroll-wrapper pa" height="100%" :data="tables" :size="theme.size" :row-class-name="tableRowClassName">
					<el-table-column label="编号" prop="no" min-width="140">
						<template slot-scope="scope">
							<el-link type="primary" @click="$refs['contract'].open(scope.row)">{{scope.row.no}}</el-link>
							<p class="c9 fs12" style="line-height: 1;">{{scope.row.out_no || '-'}}</p>
						</template>
					</el-table-column>
					<el-table-column label="建档人" prop="creator.name" min-width="70"></el-table-column>
					<el-table-column label="雇佣人" prop="employee.name" min-width="70"></el-table-column>
					<el-table-column label="类型" prop="type" min-width="70" :formatter="({type}) => { return e_contract_types[type] || '未知'; }"></el-table-column>
					<el-table-column label="状态" prop="status" min-width="70" :formatter="({status}) => { return e_contract_statuses[status] || '未知'; }"></el-table-column>
					<el-table-column label="终止原因" prop="termination_reason" min-width="90" :formatter="({termination_reason}) => { return termination_reason || '-'; }"></el-table-column>
					<el-table-column label="附件" min-width="50" :formatter="({attachments}) => { return attachments.length ? `${attachments.length} 份` : '无'; }"></el-table-column>
					<el-table-column label="工作地点" prop="work_location" min-width="80"></el-table-column>
					<el-table-column label="任职岗位" prop="position" min-width="100"></el-table-column>
					<el-table-column label="签订日期" prop="sign_at" min-width="100"></el-table-column>
					<el-table-column label="生效日期" prop="effective_at" min-width="100"></el-table-column>
					<el-table-column label="试用结束" prop="probation_at" min-width="100" :formatter="({probation_at}) => { return probation_at || '无试用期'; }"></el-table-column>
					<el-table-column label="到期日期" prop="expiration_at" min-width="100" :formatter="({expiration_at}) => { return expiration_at || '无固定期限'; }"></el-table-column>
					<el-table-column label="创建时间" prop="created_at" min-width="150"></el-table-column>
					<el-table-column label="操作" min-width="60">
						<template slot-scope="scope">
							<el-link type="primary" @click="delContract(scope.row)">删除</el-link>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<el-pagination
			slot="footer"
			:layout="$config.PAGE_LAYOUT"
			:page-sizes="$config.PAHE_SIZES"
			:page-size="params.perPage"
			:current-page="params.page"
			:total="params.total"
			@size-change="(v) => { getContracts({ ...params, page: 1, perPage: v }) }"
			@current-change="(v) => { getContracts({ ...params, page: v }) }"
			background>	
		</el-pagination>
		<template slot="other">
			<contract-show ref="contract" :status="statuses" @refresh="getContracts(params)"></contract-show>
		</template>
	</main-table-a>
</template>

<script>
	import { mapState } from 'vuex';
	import mainTableA from '@/components/main-table-a';
	import contractShow from '@/pages/hrm/contracts/show';

	export default {
		components: {
			mainTableA,
			contractShow
		},
		computed: {
			...mapState(['theme', 'models']),
			e_contract_dict () {
				if (!this.models) return {};
				return this.models.employment_contracts || {};
			},
			e_contract_types () {
				return this.e_contract_dict.types || {};
			},
			e_contract_statuses () {
				return this.e_contract_dict.statuses || {};
			}
		},
		methods: {
			tableRowClassName({ row }) {
				if (row.status == 3) return 'c8492a6 bcfafafa';
				if (!row.attachments.length) return 'bcf8ebcc';
				return 'bce1f3d8';
			},
			toggleStatus (s) {
				const { status } = this.params
				const index = status.indexOf(s);
				if (index > -1) {
					status.splice(index, 1);
					this.status_tabs[s].checked = false;
				} else {
					status.push(s);
					this.status_tabs[s].checked = true;
				}
				this.getContracts(this.params);
			},
			toggletype (s) {
				const { type } = this.params
				const index = type.indexOf(s);
				if (index > -1) {
					type.splice(index, 1);
					this.type_tabs[s].checked = false;
				} else {
					type.push(s);
					this.type_tabs[s].checked = true;
				}
				this.getContracts(this.params);
			},
			async getContracts (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_EMPLOYEES_CONTRACTS, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.tables = result.data;
				this.employees = result.employees;
				this.counts = result.counts;
				var type_tabs = {};
				const type = params.type || [];
				for (let s in this.e_contract_types) {
					type_tabs[s] = {
						name: this.e_contract_types[s],
						checked: type.indexOf(s) >= 0 ? true : false
					};
				}
				this.type_tabs = type_tabs;
				var other_tabs = {};
				const other = params.other || '';
				for (let s in result.others) {
					other_tabs[s] = {
						name: result.others[s],
						checked: other === s ? true : false
					};
				}
				this.other_tabs = other_tabs;
				var status_tabs = {};
				const status = params.status || [];
				for (let s in this.e_contract_statuses) {
					status_tabs[s] = {
						name: this.e_contract_statuses[s],
						checked: status.indexOf(s) >= 0 ? true : false
					};
				}
				this.status_tabs = status_tabs;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			},
			delContract ({ id, no, employee }) {
				this.$confirm(`${employee.name}的用工合同 ${no}`, '确定要删除吗？', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					this.handleContracts({action: 'delete', id});
				});
			},
			async handleContracts (params) {
				const res = await this.$http.get(this.$api.URI_EMPLOYEES_CONTRACTS, {params, headers: {loading: true}});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.getContracts(this.params);
				this.$message.success(msg);
			}
		},
		data() {
			return {
				tables: [],
				shops: [],
				counts: [],
				statuses: [],
				employees: [],
				type_tabs: [],
				other_tabs: [],
				status_tabs: [],
				show_fillter: false,
				params: {
					type: [],
					other: '',
					status: [],
					perPage: 10,
				}
			}
		},
		created () {
			this.getContracts(this.params, true);
		}
	};
</script>